/* ---------------------------------- */
/* ==Helpers                          */
/* ---------------------------------- */

/* State Helpers */
/* ------------- */

/* invisible for all */
.is-hidden,
[hidden] {
  display: none;
}

/* hidden but not for an assistive technology like a screen reader, Yahoo! method */
.visually-hidden {
  position: absolute !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
}

.is-disabled,
[disabled] {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
  filter: grayscale(1);
}

ul {
  &.is-unstyled,
  &.unstyled {
    list-style: none;
    padding-left: 0;
  }
}

/* Width Helpers */
/* ------------- */

/* blocks widths (percentage and pixels) */
.#{$kna-namespace}w10 {
  width: 10%;
}

.#{$kna-namespace}w20 {
  width: 20%;
}

.#{$kna-namespace}w25 {
  width: 25%;
}

.#{$kna-namespace}w30 {
  width: 30%;
}

.#{$kna-namespace}w33 {
  width: 33.3333%;
}

.#{$kna-namespace}w40 {
  width: 40%;
}

.#{$kna-namespace}w50 {
  width: 50%;
}

.#{$kna-namespace}w60 {
  width: 60%;
}

.#{$kna-namespace}w66 {
  width: 66.6666%;
}

.#{$kna-namespace}w70 {
  width: 70%;
}

.#{$kna-namespace}w75 {
  width: 75%;
}

.#{$kna-namespace}w80 {
  width: 80%;
}

.#{$kna-namespace}w90 {
  width: 90%;
}

.#{$kna-namespace}w100 {
  width: 100%;
}

.#{$kna-namespace}w50p {
  width: 50px;
}

.#{$kna-namespace}w100p {
  width: 100px;
}

.#{$kna-namespace}w150p {
  width: 150px;
}

.#{$kna-namespace}w200p {
  width: 200px;
}

.#{$kna-namespace}w300p {
  width: 300px;
}

@media (min-width: 401px) {
  .#{$kna-namespace}w400p {
    width: 400px;
  }

  .#{$kna-namespace}w500p {
    width: 500px;
  }

  .#{$kna-namespace}w600p {
    width: 600px;
  }
}

@media (min-width: 701px) {

  .#{$kna-namespace}w700p {
    width: 700px;
  }

  .#{$kna-namespace}w800p {
    width: 800px;
  }
}

@media (min-width: 961px) {
  .#{$kna-namespace}w960p {
    width: 960px;
  }

  .#{$kna-namespace}mw960p {
    max-width: 960px;
  }

  .#{$kna-namespace}w1140p {
    width: 1140px;
  }

  .#{$kna-namespace}mw1140p {
    max-width: 1140px;
  }
}

.#{$kna-namespace}wauto {
  width: auto;
}

/* Spacing Helpers */
/* --------------- */

.#{$kna-namespace}man,
.#{$kna-namespace}ma0 {
  margin: 0;
}

.#{$kna-namespace}pan,
.#{$kna-namespace}pa0 {
  padding: 0;
}

.#{$kna-namespace}mas {
  margin: $small-value;
}

.#{$kna-namespace}mam {
  margin: $medium-value;
}

.#{$kna-namespace}mal {
  margin: $large-value;
}

.#{$kna-namespace}pas {
  padding: $small-value;
}

.#{$kna-namespace}pam {
  padding: $medium-value;
}

.#{$kna-namespace}pal {
  padding: $large-value;
}

.#{$kna-namespace}mtn,
.#{$kna-namespace}mt0 {
  margin-top: 0;
}

.#{$kna-namespace}mts {
  margin-top: $small-value;
}

.#{$kna-namespace}mtm {
  margin-top: $medium-value;
}

.#{$kna-namespace}mtl {
  margin-top: $large-value;
}

.#{$kna-namespace}mrn,
.#{$kna-namespace}mr0 {
  margin-right: 0;
}

.#{$kna-namespace}mrs {
  margin-right: $small-value;
}

.#{$kna-namespace}mrm {
  margin-right: $medium-value;
}

.#{$kna-namespace}mrl {
  margin-right: $large-value;
}

.#{$kna-namespace}mbn,
.#{$kna-namespace}mb0 {
  margin-bottom: 0;
}

.#{$kna-namespace}mbs {
  margin-bottom: $small-value;
}

.#{$kna-namespace}mbm {
  margin-bottom: $medium-value;
}

.#{$kna-namespace}mbl {
  margin-bottom: $large-value;
}

.#{$kna-namespace}mln,
.#{$kna-namespace}ml0 {
  margin-left: 0;
}

.#{$kna-namespace}mls {
  margin-left: $small-value;
}

.#{$kna-namespace}mlm {
  margin-left: $medium-value;
}

.#{$kna-namespace}mll {
  margin-left: $large-value;
}

.#{$kna-namespace}mauto {
  margin: auto;
}

.#{$kna-namespace}mtauto {
  margin-top: auto;
}

.#{$kna-namespace}mrauto {
  margin-right: auto;
}

.#{$kna-namespace}mbauto {
  margin-bottom: auto;
}

.#{$kna-namespace}mlauto {
  margin-left: auto;
}

.#{$kna-namespace}ptn,
.#{$kna-namespace}pt0 {
  padding-top: 0;
}

.#{$kna-namespace}pts {
  padding-top: $small-value;
}

.#{$kna-namespace}ptm {
  padding-top: $medium-value;
}

.#{$kna-namespace}ptl {
  padding-top: $large-value;
}

.#{$kna-namespace}prn,
.#{$kna-namespace}pr0 {
  padding-right: 0;
}

.#{$kna-namespace}prs {
  padding-right: $small-value;
}

.#{$kna-namespace}prm {
  padding-right: $medium-value;
}

.#{$kna-namespace}prl {
  padding-right: $large-value;
}

.#{$kna-namespace}pbn,
.#{$kna-namespace}pb0 {
  padding-bottom: 0;
}

.#{$kna-namespace}pbs {
  padding-bottom: $small-value;
}

.#{$kna-namespace}pbm {
  padding-bottom: $medium-value;
}

.#{$kna-namespace}pbl {
  padding-bottom: $large-value;
}

.#{$kna-namespace}pln,
.#{$kna-namespace}pl0 {
  padding-left: 0;
}

.#{$kna-namespace}pls {
  padding-left: $small-value;
}

.#{$kna-namespace}plm {
  padding-left: $medium-value;
}

.#{$kna-namespace}pll {
  padding-left: $large-value;
}
