/*!
* www.KNACSS.com v6.1.2 (21 juin 2017) @author: Alsacreations, Raphael Goetter
* Licence WTFPL http://www.wtfpl.net/
*/

/* ----------------------------- */
/* ==Table Of Content            */
/* ----------------------------- */

/* 1- Normalize (basic reset) */
/* 2- Base (basic styles) */
/* 3- Print (print quick reset) */
/* 4- Stylings (minor stylings) */
/* 5- Misc (skip links, hyphens) */
/* 6- Tables (data tables consistency) */
/* 7- Forms (forms consistency) */
/* 8- Media object */
/* 9- Autogrid object */
/* 10- Global Layout (alignment, modules, positionning) */
/* 11- Helpers (width and spacers helpers) */
/* (12- WordPress reset (disabled by default)) */
/* 13- Responsive (Responsive Web Design helpers) */
/* 14- Grid Layout (grillade) */

/* ---------------------------------- */
/* ==Normalize (basic reset)          */
/* ---------------------------------- */

@import "_vendor/_normalize.scss"; // normalize (basic reset)

// WARNING : you should comment the following @import (variables)
// and move variables file from knacss folder to your own project folder!
@import "_config/_variables.scss";

@import "_config/_breakpoints.scss";
@import "_config/_mixins.scss";

// Libraries
@import "library/_base.scss"; // basic styles
@import "library/_print.scss"; // print quick reset
@import "library/_styling.scss"; // minor stylings
@import "library/_misc.scss"; // skip links, hyphens
@import "library/_tables.scss"; // data tables consistency
@import "library/_forms.scss"; // forms consistency

// Objects
@import "objects/_media.scss"; // media object
@import "objects/_autogrid.scss"; // media autogrid

// Utilities
@import "utilities/_layout.scss"; // alignment, modules, positionning
@import "utilities/_helpers.scss"; // width and spacers helpers
// @import "utilities/_wordpress.scss"; // WordPress reset and basic styles
@import "utilities/_responsive.scss"; // Responsive Web Design helpers

// Grids
@import "grids/_grillade.scss"; // grids


/* ----------------------------- */
/* ==Own stylesheet              */
/* ----------------------------- */

/* Here should go your own CSS styles */
// You can also link them with a Sass @import
// @import "my-styles";
