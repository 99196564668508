		
	// Styles Communs

		html {
			display: flex;
			flex-direction: column;
		}

		body {
			min-height: 100vh;
			display: flex;
			flex-direction: column;
			font: 300 1.8rem $font-stack-common;
		}

		main {
			flex: 1 1 auto;
		}

		::selection {
			color: $blanc;
		  	background: rgba($bleu, .8);
		}

		.is-fixed {
			width: 100%;
			height: 100%;
			position: fixed;
			overflow: hidden;
		}

		a, button {
			text-decoration: none;
			outline: none;
			color: inherit;
			cursor: pointer;
		}

		a:not(.line-hover) {
			transiton: color .3s ease-out;

			&:hover {
				color: $bleu;
			}
		}

		strong {
			font-family: $font-stack-common;
			font-weight: 400;
		}

		em {
			font-family: $font-stack-common;
			font-style: italic;
		}

		h1, h2 {
			font: {
				family: $chivo;
				style: italic;
				weight: 400;
			}
			color: $blanc;
		}

		h1 {
			font-size: 4rem;
			line-height: 4rem;

			span {
				background-color: $rouge;
			}
		}

		h2 {
			font-size: 3rem;
			line-height: 3rem;

			span {
				background-color: $bleu;
			}
		}

		////// Dimensions custo container
		
		.container {
			max-width: 115rem;
			margin: 0 auto;
			padding: 0 2rem;

			// @include respond-to('tiny-plus') {
			// 	padding: 0 1.5rem;
			// }
		}

		// Style bloc façon citation

		.citation {
			font-family: $ptserif;
			font-weight: 400;
			font-style: italic;
			color: $bleu;
		}

		// Styles animations boutons

		.line-hover, .charge-hover {
			position: relative;
			display: inline-block;
		}

		.line-hover {
			padding: 1rem 0;
			text-decoration: none;

			&:after {
				position: absolute;
				left: 0;
				bottom: 0;
				height: .1rem;
				width: 0;
				content: '';
				opacity: 0;
				background-color: $noir;
				transition: all .3s ease-out;
			}

			&:hover:after {
				width: 100%;
				opacity: 1;
			}
		}

		.charge-hover {
		    padding: .5rem 1rem;
		    color: #000;
		    background-color: #fff;
		    border: .1rem solid #000;
		    transition: color .3s ease-out;

		    &:before {
		    	position: absolute;
		    	top: 0;
		    	left: 0;
		    	right: 100%;
		    	bottom: 0;
		    	z-index: -1;
		    	background-color: $noir;
		    	transition: right .3s ease-out;
		    }

		    &:hover {
		    	color: $blanc;

		    	:before {
		    		right: 0;
		    	}
		    }
		}




	
