
	// Navigation

		header {
			&.is-mobile-nav-open {
				.navigation {
					pointer-events: auto;
					opacity: 1;
					transition: opacity .2s ease-out;
				}
			}

			.navigation {
				@media (max-width: ($extra-large - 1)) { // <= 1023px
					height: 100vh;
					flex-direction: column;
					justify-content: center;
				    align-items: center;
					position: fixed;
					overflow-x: hidden;
				    top: 0;
				    right: 0;
				    bottom: 0;
				    left: 0;
				   	z-index: -1;
					padding-top: 6rem;
				    pointer-events: none;
				    opacity: 0;
				    background: $blanc;
				}
			}

			.hamburger {
				height: 6rem;
				justify-content: center;
				align-items: center;
				margin-left: auto;

				@media (min-width: $extra-large) { // >= 1024px
					display: none;
				}
				
				.hamburger-label {
					margin-right: 1rem;
				    text-transform: uppercase;
				    font-weight: 400;
				    color: $noir;
				}
			}
		}