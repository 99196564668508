/* ----------------------------- */
/* ==Tables                      */
/* ----------------------------- */

table,
.#{$kna-namespace}table {
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  vertical-align: top;
  margin-bottom: $medium-value;
}

.#{$kna-namespace}table {
  display: table;
}

#recaptcha_table,
.#{$kna-namespace}table-auto {
  table-layout: auto;
}

td,
th {
  vertical-align: top;
  min-width: $medium-value;
  cursor: default;
}
