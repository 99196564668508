
	// Styles pour les plugins JS

		// bLazy.js – A lazyload image script
	    
		    .b-lazy {
				max-width: 100%;
				opacity: 0;
		        transition: opacity .2s ease;  
			}

		    .b-lazy.b-loaded {
		        opacity: 1;
		    }

		///// Iframe responsive fullscreen

			iframe {
				border: none;
			}

			.iframe-wrapper {
				position: relative;
			}

			.iframe-sub-wrapper {
				position: relative;
				height: 0;
				padding: 0 0 56.25%;

			    iframe, embed, object {
					position: absolute;
				    top: 0;
				    left: 0;
				    width: 100% !important;
				    height: 100% !important;
				}
			}