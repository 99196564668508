
	// Fonts (Polices de caractère)

		// Chivo

			@font-face {
			    font-family: 'Chivo';
			    src: url('../fonts/chivo/chivo-regular.woff2') format('woff2'),
			         url('../fonts/chivo/chivo-regular.woff') format('woff'),
			         url('../fonts/chivo/chivo-regular.ttf') format('truetype'),
					 url('../font/chivo/chivo-regular.svg#ChivoRegular') format('svg');
			    font-weight: 300;
			    font-style: normal;
			}

			@font-face {
			    font-family: 'Chivo';
			    src: url('../fonts/chivo/chivo-bold.woff2') format('woff2'),
			         url('../fonts/chivo/chivo-bold.woff') format('woff'),
			         url('../fonts/chivo/chivo-bold.ttf') format('truetype'),
					 url('../font/chivo/chivo-bold.svg#ChivoBold') format('svg');
			    font-weight: 400;
			    font-style: normal;
			}

			@font-face {
			    font-family: 'Chivo';
			    src: url('../fonts/chivo/chivo-italic.woff2') format('woff2'),
			         url('../fonts/chivo/chivo-italic.woff') format('woff'),
			         url('../fonts/chivo/chivo-italic.ttf') format('truetype'),
					 url('../font/chivo/chivo-italic.svg#ChivoItalic') format('svg');
			    font-weight: 300;
			    font-style: italic;
			}

			@font-face {
			    font-family: 'Chivo';
			    src: url('../fonts/chivo/chivo-bolditalic.woff2') format('woff2'),
			         url('../fonts/chivo/chivo-bolditalic.woff') format('woff'),
			         url('../fonts/chivo/chivo-bolditalic.ttf') format('truetype'),
					 url('../font/chivo/chivo-bolditalic.svg#ChivoBoldItalic') format('svg');
			    font-weight: 400;
			    font-style: italic;
			}

		// PT Serif

			@font-face {
			    font-family: 'PT Serif';
			    src: url('../fonts/ptserif/PTSerif-Caption.woff2') format('woff2'),
			         url('../fonts/ptserif/PTSerif-Caption.woff') format('woff'),
			         url('../fonts/ptserif/PTSerif-Caption.ttf') format('truetype'),
					 url('../font/ptserif/PTSerif-Caption.svg#PTSerifCaption') format('svg');
			    font-weight: 300;
			    font-style: normal;
			}

			@font-face {
			    font-family: 'PT Serif';
			    src: url('../fonts/ptserif/PTSerif-CaptionItalic.woff2') format('woff2'),
			         url('../fonts/ptserif/PTSerif-CaptionItalic.woff') format('woff'),
			         url('../fonts/ptserif/PTSerif-CaptionItalic.ttf') format('truetype'),
					 url('../font/ptserif/PTSerif-CaptionItalic.svg#PTSerifCaptionItalic') format('svg');
			    font-weight: 300;
			    font-style: italic;
			}

			@font-face {
			    font-family: 'PT Serif';
			    src: url('../fonts/ptserif/PTSerif-BoldItalic.woff2') format('woff2'),
			         url('../fonts/ptserif/PTSerif-BoldItalic.woff') format('woff'),
			         url('../fonts/ptserif/PTSerif-BoldItalic.ttf') format('truetype'),
					 url('../font/ptserif/PTSerif-BoldItalic.svg#PTSerifBoldItalic') format('svg');
			    font-weight: 400;
			    font-style: italic;
			}


