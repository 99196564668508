// Styles pour les bugs dûs à l'utilisation de "transform: ...;" au hover	

	///// Hack éléments scale tremblotants sur Safari

	.no-flickr-scale {
	    transform: translateZ(0);
	}

	///// Hack éléments rotate tremblotants sur Chrome + Opéra

	.no-flickr-rotate {
	    backface-visibility: hidden;
	}